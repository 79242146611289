import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));

// export const Apidoc = Loadable(lazy(() => import('../pages/api/Apidoc')));
// export const ApiPublic = Loadable(lazy(() => import('../pages/api/Api')));
//
export const Api = Loadable(lazy(() => import('../pages/api/Api')));

export const OrganisationsLayout = Loadable(
  lazy(() => import('../pages/configuration/organisations'))
);
export const AssessmentConfig = Loadable(lazy(() => import('../pages/configuration/assessments')));
export const ContactUs = Loadable(lazy(() => import('../pages/contactUs')));
export const AboutUs = Loadable(lazy(() => import('../pages/aboutUs')));
export const Guidance = Loadable(lazy(() => import('../pages/guidance/Guidance')));
export const AzureAD = Loadable(lazy(() => import('../pages/documentation/AzureAD')));
export const GettingStarted = Loadable(lazy(() => import('../pages/documentation/GettingStarted')));
export const ComposeGuidance = Loadable(
  lazy(() => import('../pages/configuration/guidance/ComposeGuidance'))
);
export const Research = Loadable(lazy(() => import('../pages/research')));
export const AssessmentSelect = Loadable(lazy(() => import('../pages/iTAssessment')));
export const Home = Loadable(lazy(() => import('../pages/HomePage')));
export const AssessmentReport = Loadable(lazy(() => import('../pages/reports/AssessmentReport')));
export const Questions = Loadable(lazy(() => import('../pages/configuration/questions/Questions')));

//
