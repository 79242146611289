'use client';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';

const reactPlugin = new ReactPlugin();
var clickPluginInstance = new ClickAnalyticsPlugin();
var clickPluginConfig = {
  autoCapture: true,
};
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING,
    extensions: [reactPlugin, clickPluginInstance],
    // extensionConfig: { [clickPluginInstance.identifier]: clickPluginConfig },
    extensionConfig: {
      [clickPluginInstance.identifier]: clickPluginConfig,
    },
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
  },
});
appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
  env.tags = env.tags || [];
  env.tags['ai.cloud.role'] = process.env.REACT_APP_INSIGHTS_ENV;
});

export { reactPlugin, appInsights };
