import { useRef, useContext } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, AppBar, Toolbar, Container, BoxProps, Typography } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// utils
import { bgBlur } from '../../utils/cssStyles';
// config
import { HEADER } from '../../config';
// routes
// import { PATH_DOCS, PATH_MINIMAL_ON_STORE } from '../../routes/paths';
// components
import Logo from '../../components/logo';
//
import navConfig from './nav/config';
import navConfigUnAuthed from './nav/configUnAuthed';
import navConfigOrgAdmin from './nav/configOrgAdmin';
import navConfigSuperAdmin from './nav/configSuperAdmin';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';

import { useIsAuthenticated } from '@azure/msal-react';
// import Typography from 'src/theme/overrides/Typography';

// SF
import { SfContext } from '../../SfContext';

// ----------------------------------------------------------------------

export default function Header() {
  const carouselRef = useRef(null);

  const theme = useTheme();

  const isDesktop = useResponsive('up', 'md');

  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  const isAuthenticated = useIsAuthenticated();

  const { state } = useContext(SfContext);

  let userNav = navConfigUnAuthed;
  if (isAuthenticated) {
    //  let isITperm = false;
    let isOrgAdmin = false;
    let isSuperAdmin = false;

    //  if (state.me) {
    //    state.me.organisationAssessmentPermissions.forEach((permission: any) => {
    //      if (permission.permission === 'IT') {
    //        isITperm = true;
    //      }
    //    });
    //  }

    //  if (isITperm) {
    //    userNav = navConfigAuthedITPerm;
    //  }
    if (state.me) {
      if (state.me.rolesAssignments) {
        state.me.rolesAssignments.forEach((roleAssignment: any) => {
          if (roleAssignment.role.role === 'Organisation Admin') {
            isOrgAdmin = true;
          }
          if (roleAssignment.role.role === 'SuperAdmin') {
            isSuperAdmin = true;
          }
        });
      }
    }

    if (isOrgAdmin) {
      userNav = navConfigOrgAdmin;
    } else if (isSuperAdmin) {
      userNav = navConfigSuperAdmin;
    } else if (isAuthenticated) {
      userNav = navConfig;
    }
  }

  return (
    <AppBar ref={carouselRef} color="transparent" sx={{ boxShadow: 0 }}>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Logo />

          <Typography mx={1}>Security Frameworks</Typography>

          {/* <Link
            href={PATH_DOCS.changelog}
            target="_blank"
            rel="noopener"
            underline="none"
            sx={{ ml: 1 }}
          >
            <Label color="info"> v4.0.0 </Label>
          </Link> */}

          <Box sx={{ flexGrow: 1 }} />

          {isDesktop && <NavDesktop isOffset={isOffset} data={userNav} />}

          {/* <Button variant="contained" target="_blank" rel="noopener" href={PATH_MINIMAL_ON_STORE}>
            Purchase Now
          </Button> */}

          {!isDesktop && <NavMobile isOffset={isOffset} data={userNav} />}
        </Container>
      </Toolbar>

      {isOffset && <Shadow />}
    </AppBar>
  );
}

// ----------------------------------------------------------------------

function Shadow({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
