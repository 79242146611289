import { useEffect, useState } from 'react';
// import { useMsal } from '@azure/msal-react';

import { Link, Button, Container, Box, Typography, Stack, Paper, styled } from '@mui/material';

// Azure AD
import { loginRequest } from '../../../authConfig';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import { useSnackbar } from '../../../components/snackbar';
import { Description } from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router-dom';

type error = {
  error: any;
  errorId: string;
  Title: string;
  additionalInfo: string;
};

export const AADErrors = (error: error) => {
  const { enqueueSnackbar } = useSnackbar();

  const Item = styled(Paper)(({ theme }: any) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  let navigate = useNavigate();

  useEffect(() => {
    // enqueueSnackbar('Saved', { variant: 'success' });
    console.log('error received: ', error);
    if (error.additionalInfo.includes('AADSTS650052')) {
      enqueueSnackbar('AAD Configuration Error: AADSTS650052', {
        variant: 'error',
        persist: true,
        action: (key) => (
          <>
            <Button href="/admin/documentation/azure-ad">Learn more</Button>
          </>
        ),
      });
    } else if (error.additionalInfo.includes('AADSTS65001')) {
      enqueueSnackbar('AAD Configuration Error: AADSTS65001', {
        variant: 'error',
        persist: true,
        action: (key) => (
          <>
            <Button href="/admin/documentation/azure-ad">Learn more</Button>
          </>
        ),
      });
    } else {
      enqueueSnackbar('There was an error', { variant: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return <div></div>;
};
