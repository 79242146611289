import { Navigate, useRoutes } from 'react-router-dom';
// auth
// import AuthGuard from '../auth/AuthGuard';
// import GuestGuard from '../auth/GuestGuard';
// layouts
// import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
import CompactLayout from '../layouts/compact';
import Main from '../layouts/main';
// config
// import { PATH_AFTER_LOGIN } from '../config';
//
import {
  Page500,
  Page403,
  Page404,
  ComingSoonPage,
  MaintenancePage,
  Api,
  OrganisationsLayout,
  AssessmentConfig,
  Research,
  AssessmentSelect,
  Home,
  AssessmentReport,
  Questions,
  AboutUs,
  ContactUs,
  Guidance,
  ComposeGuidance,
  AzureAD,
  GettingStarted,
} from './elements';
import { use } from 'i18next';

// SF
// import Api from '../layouts/apidoc/Api';
// import OrganisationsLayout from '../layouts/configuration/organisations';
// import AssessmentConfig from '../layouts/configuration/assessments/';
// import Research from '../layouts/research';
// import AssessmentSelect from '../layouts/iTAssessment';
// import Home from '../layouts/home';
// import AssessmentReport from '../layouts/reports/AssessmentReport';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/home" replace />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/one" replace />, index: true },
        { path: 'one', element: <ComingSoonPage /> },
        { path: 'two', element: <ComingSoonPage /> },
        { path: 'three', element: <ComingSoonPage /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/four" replace />, index: true },
            { path: 'four', element: <ComingSoonPage /> },
            { path: 'five', element: <ComingSoonPage /> },
            { path: 'six', element: <ComingSoonPage /> },
          ],
        },
        {
          path: 'framework',
          children: [
            { element: <Navigate to="/dashboard/framework/s" replace />, index: true },
            { path: 's', element: <ComingSoonPage /> },
            { path: 'e', element: <ComingSoonPage /> },
            { path: 'r', element: <ComingSoonPage /> },
          ],
        },
      ],
    },
    {
      path: '/home',
      element: <Main />,
      children: [{ element: <Home />, index: true }],
    },
    {
      path: '/assessment',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/assessment/it" replace />, index: true },
        { path: 'it', element: <AssessmentSelect /> },
      ],
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [{ path: 'guidance', element: <Guidance /> }],
    },
    {
      path: '/admin',
      element: <DashboardLayout />,
      children: [
        {
          path: '/admin/configuration',
          children: [
            { element: <Navigate to="/admin/configuration/assessments" replace />, index: true },

            { path: 'assessments', element: <AssessmentConfig /> },
            { path: 'questions', element: <Questions /> },
            { path: 'organisation', element: <OrganisationsLayout /> },
            { path: 'guidance', element: <ComposeGuidance /> },
          ],
        },
        {
          path: '/admin/reports',
          children: [
            { element: <Navigate to="/admin/reports/assessment" replace />, index: true },

            { path: 'assessment', element: <AssessmentReport /> },
          ],
        },
        {
          path: '/admin/documentation',
          children: [
            {
              element: <Navigate to="/admin/documentation/getting-started" replace />,
              index: true,
            },

            { path: 'getting-started', element: <GettingStarted /> },
            { path: 'azure-ad', element: <AzureAD /> },
          ],
        },
      ],
    },

    {
      path: '/api',
      element: <DashboardLayout />,

      children: [
        { path: '/api', element: <Api /> },
        {
          path: '/api/getting-started',
          children: [{ path: 'client-credentials', element: <Api apiDoc="clientCredentials" /> }],
        },
        {
          path: '/api/public/frameworks',
          children: [
            { element: <Navigate to="/api/public/frameworks/list" replace />, index: true },

            { path: 'list', element: <Api apiDoc="listFrameworks" /> },
            { path: 'get', element: <Api apiDoc="getFrameworks" /> },
          ],
        },
        {
          path: '/api/public/nist-csf',
          children: [
            { element: <Navigate to="/api/public/nist-csf/list" replace />, index: true },
            { path: 'list', element: <Api apiDoc="nistcsfListControls" /> },
            { path: 'get', element: <Api apiDoc="nistcsfGetControls" /> },
            {
              path: 'list-infortmative-references',
              element: <Api apiDoc="nistcsfListInformativeReferences" />,
            },
            {
              path: 'get-infortmative-references',
              element: <Api apiDoc="nistcsfGetInformativeReferences" />,
            },
          ],
        },
        {
          path: '/api/public/nist-800-53r3',
          children: [
            { element: <Navigate to="/api/public/nist-800-53r3/list" replace />, index: true },
            { path: 'list', element: <Api apiDoc="nist80053r2ListControls" /> },
            { path: 'get', element: <Api apiDoc="nist80053r2GetControls" /> },
          ],
        },
        {
          path: '/api/public/ism',
          children: [
            { element: <Navigate to="/api/public/ism/list" replace />, index: true },
            { path: 'list', element: <Api apiDoc="ismListControls" /> },
            { path: 'get', element: <Api apiDoc="ismGetControls" /> },
          ],
        },
      ],
    },
    {
      path: '/api/authenticated',
      element: <DashboardLayout />,
      children: [
        {
          path: '/api/authenticated/assessments',
          children: [
            {
              element: (
                <Navigate
                  to="/api/authenticated/assessments/list-organisation-assessment"
                  replace
                />
              ),
              index: true,
            },
            { path: 'information', element: <ComingSoonPage /> },
            {
              path: '/api/authenticated/assessments/list-organisation-assessment',
              element: <Api apiDoc="listOrganisationAssessments" />,
            },
            {
              path: '/api/authenticated/assessments/get-organisation-assessment',
              element: <Api apiDoc="getOrganisationAssessment" />,
            },
            {
              path: '/api/authenticated/assessments/create-organisation-assessment',
              element: <Api apiDoc="createOrganisationAssessment" />,
            },
            {
              path: '/api/authenticated/assessments/update-organisation-assessment',
              element: <Api apiDoc="updateOrganisationAssessment" />,
            },
            {
              path: '/api/authenticated/assessments/delete-organisation-assessment',
              element: <Api apiDoc="deleteOrganisationAssessment" />,
            },
            {
              path: '/api/authenticated/assessments/list-user-assessment',
              element: <Api apiDoc="listUserAssessments" />,
            },
            {
              path: '/api/authenticated/assessments/get-user-assessment',
              element: <Api apiDoc="getUserAssessment" />,
            },
            {
              path: '/api/authenticated/assessments/create-user-assessment',
              element: <Api apiDoc="createUserAssessment" />,
            },
            {
              path: '/api/authenticated/assessments/delete-user-assessment',
              element: <Api apiDoc="deleteUserAssessment" />,
            },
            {
              path: '/api/authenticated/assessments/list-nist-csf-questions',
              element: <Api apiDoc="listNistcsfQuestions" />,
            },
            {
              path: '/api/authenticated/assessments/get-nist-csf-questions',
              element: <Api apiDoc="getNistcsfQuestion" />,
            },
            {
              path: '/api/authenticated/assessments/create-nist-csf-questions',
              element: <Api apiDoc="createNistcsfQuestion" />,
            },
            {
              path: '/api/authenticated/assessments/delete-nist-csf-questions',
              element: <Api apiDoc="deleteNistcsfQuestion" />,
            },
          ],
        },
        {
          path: '/api/authenticated/roles',
          children: [
            { element: <Navigate to="/api/authenticated/roles/list" replace />, index: true },
            {
              path: '/api/authenticated/roles/list',
              element: <Api apiDoc="listRoles" />,
            },
            {
              path: '/api/authenticated/roles/get',
              element: <Api apiDoc="getRoles" />,
            },
            {
              path: '/api/authenticated/roles/list-role-assignment',
              element: <Api apiDoc="listRoleAssignment" />,
            },
            {
              path: '/api/authenticated/roles/get-role-assignment',
              element: <Api apiDoc="getRoleAssignment" />,
            },
            {
              path: '/api/authenticated/roles/create-role-assignment',
              element: <Api apiDoc="createRoleAssignment" />,
            },
            {
              path: '/api/authenticated/roles/delete-role-assignment',
              element: <Api apiDoc="deleteRoleAssignment" />,
            },
          ],
        },
        {
          path: '/api/authenticated/reports',
          children: [
            { element: <Navigate to="/api/authenticated/reports/list" replace />, index: true },
            {
              path: '/api/authenticated/reports/list',
              element: <Api apiDoc="listReports" />,
            },
            {
              path: '/api/authenticated/reports/get',
              element: <Api apiDoc="getReports" />,
            },
            {
              path: '/api/authenticated/reports/list-controls',
              element: <Api apiDoc="listControlReport" />,
            },
            {
              path: '/api/authenticated/reports/get-controls',
              element: <Api apiDoc="getControlReport" />,
            },
            {
              path: '/api/authenticated/reports/get-category',
              element: <Api apiDoc="getCategoryReport" />,
            },
            {
              path: '/api/authenticated/reports/get-user-report',
              element: <Api apiDoc="getUserReport" />,
            },
          ],
        },
        {
          path: '/api/authenticated/organisation',
          children: [
            {
              element: <Navigate to="/api/authenticated/organisation/get" replace />,
              index: true,
            },
            { path: 'get', element: <Api apiDoc="getOrganisation" /> },
          ],
        },
        {
          path: '/api/authenticated/users',
          children: [
            { element: <Navigate to="/api/authenticated/users/list" replace />, index: true },
            { path: 'list', element: <Api apiDoc="listUsers" /> },
            { path: 'get', element: <Api apiDoc="getUser" /> },
            { path: 'create', element: <Api apiDoc="createUser" /> },
          ],
        },
      ],
    },
    {
      path: '/research',
      element: <Main />,
      children: [
        { element: <Navigate to="/research/stages" replace />, index: true },
        { path: 'stages', element: <Research /> },
      ],
    },
    {
      path: '/',
      element: <Main />,
      children: [
        { element: <Navigate to="/about-us" replace /> },
        { path: 'about-us', element: <AboutUs /> },
      ],
    },
    {
      path: '/',
      element: <Main />,
      children: [
        { element: <Navigate to="/contact-us" replace /> },
        { path: 'contact-us', element: <ContactUs /> },
      ],
    },

    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

//
