import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Divider, Stack, MenuItem, Avatar, Typography } from '@mui/material';
// routes
// import { PATH_AUTH } from '../../../routes/paths';
// auth
// import { useAuthContext } from '../../../auth/useAuthContext';
// components
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
// Azure AD
import { loginRequest } from '../../../authConfig';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

// SF
import { SfContext } from '../../../SfContext';

// components
import Iconify from '../../../components/iconify';
// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  // {
  //   label: 'Profile',
  //   linkTo: '/',
  // },
  // {
  //   label: 'Settings',
  //   linkTo: '/',
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  // const { user, logout } = useAuthContext();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const { state } = useContext(SfContext);
  const { instance } = useMsal();
  const [userIcon, setUserIcon] = useState<string | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  // const handleLogout = async () => {
  //   try {
  //     logout();
  //     navigate(PATH_AUTH.login, { replace: true });
  //     handleClosePopover();
  //   } catch (error) {
  //     console.error(error);
  //     enqueueSnackbar('Unable to logout!', { variant: 'error' });
  //   }
  // };

  const handleClickItem = (path: string) => {
    handleClosePopover();
    navigate(path);
  };

  async function handleLogin(instance: IPublicClientApplication) {
    console.log('loginRequest', loginRequest);
    try {
      instance.loginPopup(loginRequest).catch((e) => {
        console.error(e);
      });
    } catch (ee) {
      console.error('ee', ee);
    }
  }

  function handleLogout(instance: IPublicClientApplication) {
    instance.logoutPopup().catch((e) => {
      console.error(e);
    });
  }
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    setUserIcon(state.userIcon);
  }, [state]);

  return (
    // <>
    //   <IconButtonAnimate
    //     onClick={handleOpenPopover}
    //     sx={{
    //       p: 0,
    //       ...(openPopover && {
    //         '&:before': {
    //           zIndex: 1,
    //           content: "''",
    //           width: '100%',
    //           height: '100%',
    //           borderRadius: '50%',
    //           position: 'absolute',
    //           bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
    //         },
    //       }),
    //     }}
    //   >
    //     <CustomAvatar src={user?.photoURL} alt={user?.displayName} name={user?.displayName} />
    //   </IconButtonAnimate>

    //   <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
    //     <Box sx={{ my: 1.5, px: 2.5 }}>
    //       <Typography variant="subtitle2" noWrap>
    //         {user?.displayName}
    //       </Typography>

    //       <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
    //         {user?.email}
    //       </Typography>
    //     </Box>

    //     <Divider sx={{ borderStyle: 'dashed' }} />

    //     <Stack sx={{ p: 1 }}>
    //       {OPTIONS.map((option) => (
    //         <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
    //           {option.label}
    //         </MenuItem>
    //       ))}
    //     </Stack>

    //     <Divider sx={{ borderStyle: 'dashed' }} />

    //     <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
    //       Logout
    //     </MenuItem>
    //   </MenuPopover>
    // </>
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {userIcon ? (
          <Avatar src={userIcon} alt="Rayan Mssoran" />
        ) : (
          <Iconify icon="carbon:user-avatar-filled" sx={{ width: 40, height: 40 }} />
        )}
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        anchorEl={openPopover}
        onClose={handleClosePopover}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        {/* <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            Username
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            username@gmail.com
          </Typography>
        </Box> */}

        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              data-link={option.linkTo}
              onClick={(e) => handleClickItem(option.linkTo)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {isAuthenticated && state.me?.firstName && state.me?.lastName ? (
          <>
            <MenuItem sx={{ m: 1 }}>{state.me.firstName + ' ' + state.me.lastName}</MenuItem>
          </>
        ) : null}

        <Divider sx={{ borderStyle: 'dashed' }} />

        {isAuthenticated ? (
          <>
            <MenuItem sx={{ m: 1 }} onClick={() => handleLogout(instance)}>
              Sign-out
            </MenuItem>
          </>
        ) : (
          <MenuItem sx={{ m: 1 }} onClick={() => handleLogin(instance)}>
            Sign-in
          </MenuItem>
        )}
      </MenuPopover>
    </>
  );
}
