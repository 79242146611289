import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';

export const SignOut = () => {
  const { instance } = useMsal();

  const handleLogout = (logoutType) => {
    console.log('asugjhvb');
    if (logoutType === 'popup') {
      instance.logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/', // redirects the top level app after logout
      });
    }
  };

  useEffect(() => {
    handleLogout('popup');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};
