// routes
// import { PATH_AUTH, PATH_DOCS, PATH_PAGE } from '../../../routes/paths';
// config
// import { PATH_AFTER_LOGIN } from '../../../config';
// components
import Iconify from '../../../components/iconify';
import { SignOut } from '../../../components/Sf/AzureAD/SignOut';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="eva:home-fill" />,
    path: '/home',
  },
  {
    title: 'About Us',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/about-us',
  },
  {
    title: 'Assessments',
    path: '/pages',
    icon: <Iconify icon="eva:file-fill" />,
    children: [
      {
        subheader: 'Documentation',
        items: [
          { title: 'Getting Started', path: '/admin/documentation/getting-started' },
          { title: 'Azure AD', path: '/admin/documentation/azure-ad' },
        ],
      },
      {
        subheader: 'Configure',
        items: [
          { title: 'Assessments', path: '/admin/configuration/assessments' },
          { title: 'Questions', path: '/admin/configuration/questions' },
          { title: 'Organisation', path: '/admin/configuration/organisation' },
        ],
      },
      {
        subheader: 'Assessments',
        items: [
          { title: 'IT Assessment', path: '/assessment/it' },
          { title: 'Reports', path: '/admin/reports/assessment' },
          { title: 'Guidance', path: '/guidance' },
        ],
      },
      {
        subheader: 'API',
        items: [{ title: 'API Reference', path: '/api' }],
      },
      // {
      //   subheader: 'Dashboard',
      //   items: [
      //     {
      //       title: 'Dashboard',
      //       path: '/api/authenticated/assessments/list-organisation-assessment',
      //     },
      //   ],
      // },
    ],
  },
  {
    title: 'Research',
    icon: <Iconify icon="eva:book-open-fill" />,
    path: '/research/stages',
  },
  {
    title: 'Contact',
    icon: <Iconify icon="eva:book-open-fill" />,
    path: '/contact-us',
  },
  {
    title: 'Sign-out',
    icon: <Iconify icon="eva:book-open-fill" />,
    element: <SignOut />,
    path: '#',
  },
];

export default navConfig;
