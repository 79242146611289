import React, { createContext, useReducer } from 'react';

export const SfContext = createContext();

const reducer = (state, pair) => ({ ...state, ...pair });

const initialState = {
  userIcon: null,
  me: null,
};

export function SfProvider(props) {
  const [state, update] = useReducer(reducer, initialState);

  return <SfContext.Provider value={{ state, update }}>{props.children}</SfContext.Provider>;
}
