import { useEffect, useState } from 'react';
// import { useMsal } from '@azure/msal-react';

import { Button, Container, Box, Typography, Stack, Paper, styled } from '@mui/material';

// Azure AD
import { loginRequest } from '../../../authConfig';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { AADErrors } from './AADErrors';

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignIn = () => {
  const { instance } = useMsal();
  const [signinFailed, setSigninFailed] = useState(false);
  const [error, setError] = useState<any>('');

  // };
  async function handleLogin(instance: IPublicClientApplication) {
    try {
      instance.loginPopup(loginRequest).catch((e) => {
        console.error(e);
        setError(e);
        setSigninFailed(true);
      });
    } catch (ee) {
      console.error('ee', ee);
      setSigninFailed(true);
    }
  }

  const Item = styled(Paper)(({ theme }: any) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  useEffect(() => {
    handleLogin(instance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {signinFailed ? (
        <>
          <AADErrors error={error} errorId={''} Title={''} additionalInfo={error.errorMessage} />
          <Container>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100vh"
              sx={{ marginTop: '-100px' }}
            >
              <Stack>
                <Item>
                  <Typography>Looks like you're not signed-in</Typography>
                </Item>
                <Item>
                  <Button variant="contained" onClick={() => handleLogin(instance)}>
                    Sign-in
                  </Button>
                </Item>
              </Stack>
            </Box>
          </Container>
        </>
      ) : (
        <>
          <Container>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100vh"
              sx={{ marginTop: '-100px' }}
            >
              <Stack>
                <Item>
                  <Typography>Signing you in</Typography>
                </Item>
              </Stack>
            </Box>
          </Container>
        </>
      )}
    </div>
  );
};
