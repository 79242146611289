import { useEffect, useState, useContext } from 'react';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Typography, Link, Avatar } from '@mui/material';
// auth
// import { useAuthContext } from '../../../auth/useAuthContext';
// components
// import { CustomAvatar } from '../../../components/custom-avatar';
// components
import Iconify from '../../../components/iconify';
// Azure AD
import { useMsal, useAccount } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { graphConfig, protectedResources } from '../../../authConfig';
// SF
import { SfContext } from '../../../SfContext';
import { useAPI, useOnlineStatus } from 'src/common';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

// type Props = {
//   isCollapse: boolean | undefined;
// };

export default function NavAccount() {
  const {
    data: stateFromAPI,
    isLoaded: isLoadedStateFromAPI,
    status: statusStateFromAPI,
    errors: errorsStateFromAPI,
  } = useAPI(`${protectedResources.apiHello.endpoint}/me`, 'GET', null, {
    showSnackbar: false,
    writeConsole: true,
  });

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [graphUser, setGraphUser] = useState<string | null>(null);
  const { state, update } = useContext(SfContext);
  const isOnline = useOnlineStatus();

  useEffect(() => {
    update({ me: stateFromAPI });
  }, [stateFromAPI]);

  useEffect(() => {
    let responseStatus: string | number | null = null;
    if (account && inProgress === 'none' && graphUser === null && isOnline) {
      instance
        .acquireTokenSilent({
          scopes: graphConfig.scopes,
          account,
        })
        .then(async (response) => {
          try {
            fetch(`${graphConfig.endpoint}/me/photos/96x96/$value`, {
              method: 'GET', // or 'PUT'
              headers: {
                Authorization: 'Bearer ' + response.accessToken,
                'Content-Type': 'application/json',
              },
            })
              .then((response) => {
                responseStatus = response.status;
                return response.blob();
              })
              .then((imageBlob) => {
                if (responseStatus === 200) {
                  const imageObjectURL = URL.createObjectURL(imageBlob);
                  setGraphUser(imageObjectURL);
                  console.log('me', imageObjectURL);
                  // document.cookie = `userIcon=${imageObjectURL}`;
                  update({ userIcon: imageObjectURL });
                }
              })
              .catch((error) => console.error('Unable to get items.', error));
          } catch (error) {
            console.log(error);
            throw new InteractionRequiredAuthError(error);
          }
        })
        .catch((error) => {
          console.log(error);
          // in case if silent token acquisition fails, fallback to an interactive method
          if (error instanceof InteractionRequiredAuthError) {
            if (account && inProgress === 'none') {
              instance
                .acquireTokenPopup({
                  scopes: graphConfig.scopes,
                  //
                })
                .then(async (response) => {
                  fetch(`${graphConfig.endpoint}/me/photos/96x96/$value`, {
                    method: 'GET', // or 'PUT'
                    headers: {
                      Authorization: 'Bearer ' + response.accessToken,
                      'Content-Type': 'application/json',
                    },
                  })
                    .then((response) => {
                      responseStatus = response.status;
                      return response.blob();
                    })
                    .then((imageBlob) => {
                      if (responseStatus === 200) {
                        const imageObjectURL = URL.createObjectURL(imageBlob);
                        setGraphUser(imageObjectURL);
                        // document.cookie = `userIcon=${imageObjectURL}`;
                        update({ userIcon: imageObjectURL });
                      }
                    })
                    .catch((error) => console.error('Unable to get items.', error));
                })
                .catch((error1) => console.log(error1));
            }
          }
        });
    }
  }, [instance, inProgress, account]);

  return (
    <Link underline="none" color="inherit">
      {account ? (
        <StyledRoot
        // sx={{
        //   ...(isCollapse && {
        //     bgcolor: 'transparent',
        //   }),
        // }}
        >
          {graphUser ? (
            <Avatar src={graphUser} alt={account ? account.name : ''} />
          ) : (
            <Iconify icon="carbon:user-avatar-filled" sx={{ width: 40, height: 40 }} />
          )}

          <Box
            sx={{
              ml: 2,
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.shorter,
                }),
              // ...(isCollapse && {
              //   ml: 0,
              //   width: 0,
              // }),
            }}
          >
            <Typography variant="subtitle2">{account ? account.name : null}</Typography>
            {/* <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }} /> */}
          </Box>
        </StyledRoot>
      ) : null}
    </Link>
  );
}
