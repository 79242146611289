import { useCallback, useEffect, useState } from 'react';
// Azure AD
import { useMsal, useAccount } from '@azure/msal-react';

import { protectedResources } from './authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

import { useSnackbar } from './components/snackbar';
import { Errors, Options } from './@types/errors';

const optionDefaults: Options = {
  showSnackbar: false,
  errorMessages: {
    200: 'Success',
    201: 'Created',
    204: 'No Content',
    400: 'Bad Request',
    401: 'Unauthorized',
    403: 'Insufficient permissions',
    404: 'Not found',
    405: 'Method Not Allowed',
    409: 'Conflict',
    500: 'Server error',
    501: 'Not Implemented',
    503: 'Service Unavailable',
    504: 'Gateway Timeout',
  },
  writeConsole: true,
  showInnerError: true,
};

const errorsDefault: Errors = {
  message: '',
  innerError: '',
  isOnline: true,
};

const getOnLineStatus = () =>
  typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true;

export function useOnlineStatus() {
  const [status, setStatus] = useState(getOnLineStatus());

  const setOnline = () => setStatus(true);
  const setOffline = () => setStatus(false);

  useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return status;
}

export function useAPI<T>(
  url: string,
  method: string = 'GET',
  body: any = null,
  options: Options | any = optionDefaults
) {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [status, setStatus] = useState<number>();
  const [data, setData] = useState<T | null>(null);
  const [errors, setErrors] = useState<Errors>(errorsDefault);
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { enqueueSnackbar } = useSnackbar();
  const isOnline = useOnlineStatus();

  if (!isOnline) {
    enqueueSnackbar('You appear to be offline', { variant: 'error' });
  }
  useEffect(() => {
    setErrors({ ...errors, isOnline: navigator.onLine });
  }, [isOnline]);

  // Merge default errors with custom errors
  options = { ...optionDefaults, ...options };
  // console.log('options', options);

  useEffect(() => {
    if (account && inProgress === 'none' && isOnline) {
      instance
        .acquireTokenSilent({
          scopes: protectedResources.apiHello.scopes,
          account,
        })
        .then(async (response) => {
          try {
            const fetchResponse = await fetch(url, {
              method: method,
              headers: {
                Authorization: `Bearer ${response.accessToken}`,
                'Content-Type': 'application/json',
              },
            });

            const fetchResponseJson = await fetchResponse.json();
            setIsLoaded(true);
            setStatus(fetchResponse.status);

            if (fetchResponse.status === 200) {
              setData(fetchResponseJson as T);

              options.showSnackbar &&
                enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                  variant: 'success',
                  preventDuplicate: false,
                });
              options.writeConsole &&
                console.log(
                  `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                    options.errorMessages[fetchResponse.status]
                  } response.`
                );
            } else if (fetchResponse.status === 201) {
              setData(fetchResponseJson as T);

              options.showSnackbar &&
                enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                  variant: 'success',
                  preventDuplicate: false,
                });
              options.writeConsole &&
                console.log(
                  `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                    options.errorMessages[fetchResponse.status]
                  } response.`
                );
            } else if (fetchResponse.status === 204) {
              options.showSnackbar &&
                enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                  variant: 'success',
                  preventDuplicate: false,
                });
              options.writeConsole &&
                console.log(
                  `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                    options.errorMessages[fetchResponse.status]
                  } response.`
                );
            } else if (fetchResponse.status === 400) {
              options.showSnackbar &&
                enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                  variant: 'error',
                  preventDuplicate: false,
                });
              options.writeConsole &&
                console.log(
                  `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                    options.errorMessages[fetchResponse.status]
                  } response.`
                );
            } else if (fetchResponse.status === 401) {
              options.showSnackbar &&
                enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                  variant: 'error',
                  preventDuplicate: false,
                });
              options.writeConsole &&
                console.log(
                  `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                    options.errorMessages[fetchResponse.status]
                  } response.`
                );
            } else if (fetchResponse.status === 403) {
              options.showSnackbar &&
                enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                  variant: 'error',
                  preventDuplicate: false,
                });
              options.writeConsole &&
                console.log(
                  `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                    options.errorMessages[fetchResponse.status]
                  } response.`
                );
            } else if (fetchResponse.status === 404) {
              options.showSnackbar &&
                enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                  variant: 'error',
                  preventDuplicate: false,
                });
              options.writeConsole &&
                console.log(
                  `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                    options.errorMessages[fetchResponse.status]
                  } response.`
                );
            } else if (fetchResponse.status === 405) {
              options.showSnackbar &&
                enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                  variant: 'error',
                  preventDuplicate: false,
                });
              options.writeConsole &&
                console.log(
                  `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                    options.errorMessages[fetchResponse.status]
                  } response.`
                );
            } else if (fetchResponse.status === 409) {
              options.showSnackbar &&
                enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                  variant: 'warning',
                  preventDuplicate: false,
                });
              options.writeConsole &&
                console.log(
                  `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                    options.errorMessages[fetchResponse.status]
                  } response.`
                );
            } else if (fetchResponse.status === 500) {
              options.showSnackbar &&
                enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                  variant: 'error',
                  preventDuplicate: false,
                });
              options.writeConsole &&
                console.log(
                  `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                    options.errorMessages[fetchResponse.status]
                  } response.`
                );
            } else if (fetchResponse.status === 501) {
              options.showSnackbar &&
                enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                  variant: 'error',
                  preventDuplicate: false,
                });
              options.writeConsole &&
                console.log(
                  `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                    options.errorMessages[fetchResponse.status]
                  } response.`
                );
            } else if (fetchResponse.status === 503) {
              options.showSnackbar &&
                enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                  variant: 'error',
                  preventDuplicate: false,
                });
              options.writeConsole &&
                console.log(
                  `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                    options.errorMessages[fetchResponse.status]
                  } response.`
                );
            } else if (fetchResponse.status === 504) {
              options.showSnackbar &&
                enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                  variant: 'error',
                  preventDuplicate: false,
                });
              options.writeConsole &&
                console.log(
                  `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                    options.errorMessages[fetchResponse.status]
                  } response.`
                );
            } else {
              enqueueSnackbar('Please try again.', { variant: 'error' });
            }
          } catch (error) {
            console.log(error);
            throw new InteractionRequiredAuthError(error);
          }
        })
        .catch((error) => {
          console.log(error);
          // in case if silent token acquisition fails, fallback to an interactive method
          if (error instanceof InteractionRequiredAuthError) {
            if (account && inProgress === 'none') {
              instance
                .acquireTokenPopup({
                  scopes: protectedResources.apiHello.scopes,
                })
                .then(async (response) => {
                  const fetchResponse = await fetch(url, {
                    method: method,
                    headers: {
                      Authorization: `Bearer ${response.accessToken}`,
                      'Content-Type': 'application/json',
                    },
                  });

                  const fetchResponseJson = await fetchResponse.json();
                  setIsLoaded(true);
                  setStatus(fetchResponse.status);

                  if (fetchResponse.status === 200) {
                    setData(fetchResponseJson as T);

                    options.showSnackbar &&
                      enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                        variant: 'success',
                      });
                    options.writeConsole &&
                      console.log(
                        `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                          options.errorMessages[fetchResponse.status]
                        } response.`
                      );
                  } else if (fetchResponse.status === 201) {
                    setData(fetchResponseJson as T);

                    options.showSnackbar &&
                      enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                        variant: 'success',
                      });
                    options.writeConsole &&
                      console.log(
                        `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                          options.errorMessages[fetchResponse.status]
                        } response.`
                      );
                  } else if (fetchResponse.status === 204) {
                    options.showSnackbar &&
                      enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                        variant: 'success',
                      });
                    options.writeConsole &&
                      console.log(
                        `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                          options.errorMessages[fetchResponse.status]
                        } response.`
                      );
                  } else if (fetchResponse.status === 400) {
                    options.showSnackbar &&
                      enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                        variant: 'error',
                      });
                    options.writeConsole &&
                      console.log(
                        `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                          options.errorMessages[fetchResponse.status]
                        } response.`
                      );
                  } else if (fetchResponse.status === 401) {
                    options.showSnackbar &&
                      enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                        variant: 'error',
                      });
                    options.writeConsole &&
                      console.log(
                        `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                          options.errorMessages[fetchResponse.status]
                        } response.`
                      );
                  } else if (fetchResponse.status === 403) {
                    options.showSnackbar &&
                      enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                        variant: 'error',
                      });
                    options.writeConsole &&
                      console.log(
                        `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                          options.errorMessages[fetchResponse.status]
                        } response.`
                      );
                  } else if (fetchResponse.status === 404) {
                    options.showSnackbar &&
                      enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                        variant: 'error',
                      });
                    options.writeConsole &&
                      console.log(
                        `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                          options.errorMessages[fetchResponse.status]
                        } response.`
                      );
                  } else if (fetchResponse.status === 405) {
                    options.showSnackbar &&
                      enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                        variant: 'error',
                      });
                    options.writeConsole &&
                      console.log(
                        `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                          options.errorMessages[fetchResponse.status]
                        } response.`
                      );
                  } else if (fetchResponse.status === 409) {
                    options.showSnackbar &&
                      enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                        variant: 'warning',
                      });
                    options.writeConsole &&
                      console.log(
                        `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                          options.errorMessages[fetchResponse.status]
                        } response.`
                      );
                  } else if (fetchResponse.status === 500) {
                    options.showSnackbar &&
                      enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                        variant: 'error',
                      });
                    options.writeConsole &&
                      console.log(
                        `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                          options.errorMessages[fetchResponse.status]
                        } response.`
                      );
                  } else if (fetchResponse.status === 501) {
                    options.showSnackbar &&
                      enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                        variant: 'error',
                      });
                    options.writeConsole &&
                      console.log(
                        `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                          options.errorMessages[fetchResponse.status]
                        } response.`
                      );
                  } else if (fetchResponse.status === 503) {
                    options.showSnackbar &&
                      enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                        variant: 'error',
                      });
                    options.writeConsole &&
                      console.log(
                        `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                          options.errorMessages[fetchResponse.status]
                        } response.`
                      );
                  } else if (fetchResponse.status === 504) {
                    options.showSnackbar &&
                      enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                        variant: 'error',
                      });
                    options.writeConsole &&
                      console.log(
                        `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                          options.errorMessages[fetchResponse.status]
                        } response.`
                      );
                  } else {
                    enqueueSnackbar('Please try again.', { variant: 'error' });
                  }
                })
                .catch((error1) => console.log(error1));
            }
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, inProgress, isOnline]);
  return { data, isLoaded, status, errors };
}

export function useAPICallback<T>() {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [status, setStatus] = useState<number>();
  const [data, setData] = useState<T | null>(null);
  const [errors, setErrors] = useState<Errors>(errorsDefault);
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { enqueueSnackbar } = useSnackbar();

  const callAPI = useCallback(
    (
      url: string,
      method: string = 'POST',
      body: any = null,
      options: Options | any = optionDefaults
    ) => {
      // Merge default errors with custom errors
      options = { ...optionDefaults, ...options };

      if (account && inProgress === 'none') {
        instance
          .acquireTokenSilent({
            scopes: protectedResources.apiHello.scopes,
            account,
          })
          .then(async (response) => {
            try {
              const fetchResponse = await fetch(url, {
                method: method,
                headers: {
                  Authorization: `Bearer ${response.accessToken}`,
                  'Content-Type': 'application/json',
                },
                body,
              });

              const fetchResponseJson = await fetchResponse.json();
              setIsLoaded(true);
              setStatus(fetchResponse.status);

              if (fetchResponse.status === 200) {
                setData(fetchResponseJson as T);

                options.showSnackbar &&
                  enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                    variant: 'success',
                    preventDuplicate: false,
                  });
                options.writeConsole &&
                  console.log(
                    `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                      options.errorMessages[fetchResponse.status]
                    } response.`
                  );
              } else if (fetchResponse.status === 201) {
                setData(fetchResponseJson as T);
                options.showSnackbar &&
                  enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                    variant: 'success',
                    preventDuplicate: false,
                  });
                options.writeConsole &&
                  console.log(
                    `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                      options.errorMessages[fetchResponse.status]
                    } response.`
                  );
              } else if (fetchResponse.status === 204) {
                options.showSnackbar &&
                  enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                    variant: 'success',
                    preventDuplicate: false,
                  });
                options.writeConsole &&
                  console.log(
                    `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                      options.errorMessages[fetchResponse.status]
                    } response.`
                  );
              } else if (fetchResponse.status === 400) {
                options.showSnackbar &&
                  enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                    variant: 'error',
                    preventDuplicate: false,
                  });
                options.writeConsole &&
                  console.log(
                    `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                      options.errorMessages[fetchResponse.status]
                    } response.`
                  );
              } else if (fetchResponse.status === 401) {
                options.showSnackbar &&
                  enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                    variant: 'error',
                    preventDuplicate: false,
                  });
                options.writeConsole &&
                  console.log(
                    `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                      options.errorMessages[fetchResponse.status]
                    } response.`
                  );
              } else if (fetchResponse.status === 403) {
                options.showSnackbar &&
                  enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                    variant: 'error',
                    preventDuplicate: false,
                  });
                options.writeConsole &&
                  console.log(
                    `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                      options.errorMessages[fetchResponse.status]
                    } response.`
                  );
              } else if (fetchResponse.status === 404) {
                options.showSnackbar &&
                  enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                    variant: 'error',
                    preventDuplicate: false,
                  });
                options.writeConsole &&
                  console.log(
                    `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                      options.errorMessages[fetchResponse.status]
                    } response.`
                  );
              } else if (fetchResponse.status === 405) {
                options.showSnackbar &&
                  enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                    variant: 'error',
                    preventDuplicate: false,
                  });
                options.writeConsole &&
                  console.log(
                    `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                      options.errorMessages[fetchResponse.status]
                    } response.`
                  );
              } else if (fetchResponse.status === 409) {
                options.showSnackbar &&
                  enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                    variant: 'warning',
                    preventDuplicate: false,
                  });
                options.writeConsole &&
                  console.log(
                    `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                      options.errorMessages[fetchResponse.status]
                    } response.`
                  );
              } else if (fetchResponse.status === 500) {
                options.showSnackbar &&
                  enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                    variant: 'error',
                    preventDuplicate: false,
                  });
                options.writeConsole &&
                  console.log(
                    `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                      options.errorMessages[fetchResponse.status]
                    } response.`
                  );
              } else if (fetchResponse.status === 501) {
                options.showSnackbar &&
                  enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                    variant: 'error',
                    preventDuplicate: false,
                  });
                options.writeConsole &&
                  console.log(
                    `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                      options.errorMessages[fetchResponse.status]
                    } response.`
                  );
              } else if (fetchResponse.status === 503) {
                options.showSnackbar &&
                  enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                    variant: 'error',
                    preventDuplicate: false,
                  });
                options.writeConsole &&
                  console.log(
                    `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                      options.errorMessages[fetchResponse.status]
                    } response.`
                  );
              } else if (fetchResponse.status === 504) {
                options.showSnackbar &&
                  enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                    variant: 'error',
                    preventDuplicate: false,
                  });
                options.writeConsole &&
                  console.log(
                    `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                      options.errorMessages[fetchResponse.status]
                    } response.`
                  );
              } else {
                enqueueSnackbar('Please try again.', { variant: 'error' });
              }
            } catch (error) {
              console.log(error);
              throw new InteractionRequiredAuthError(error);
            }
          })
          .catch((error) => {
            console.log(error);
            // in case if silent token acquisition fails, fallback to an interactive method
            if (error instanceof InteractionRequiredAuthError) {
              if (account && inProgress === 'none') {
                instance
                  .acquireTokenPopup({
                    scopes: protectedResources.apiHello.scopes,
                  })
                  .then(async (response) => {
                    const fetchResponse = await fetch(url, {
                      method: method,
                      headers: {
                        Authorization: `Bearer ${response.accessToken}`,
                        'Content-Type': 'application/json',
                      },
                      body,
                    });

                    const fetchResponseJson = await fetchResponse.json();
                    setIsLoaded(true);
                    setStatus(fetchResponse.status);

                    if (fetchResponse.status === 200) {
                      setData(fetchResponseJson as T);

                      options.showSnackbar &&
                        enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                          variant: 'success',
                        });
                      options.writeConsole &&
                        console.log(
                          `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                            options.errorMessages[fetchResponse.status]
                          } response.`
                        );
                    } else if (fetchResponse.status === 201) {
                      setData(fetchResponseJson as T);
                      options.showSnackbar &&
                        enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                          variant: 'success',
                        });
                      options.writeConsole &&
                        console.log(
                          `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                            options.errorMessages[fetchResponse.status]
                          } response.`
                        );
                    } else if (fetchResponse.status === 204) {
                      options.showSnackbar &&
                        enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                          variant: 'success',
                        });
                      options.writeConsole &&
                        console.log(
                          `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                            options.errorMessages[fetchResponse.status]
                          } response.`
                        );
                    } else if (fetchResponse.status === 400) {
                      options.showSnackbar &&
                        enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                          variant: 'error',
                        });
                      options.writeConsole &&
                        console.log(
                          `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                            options.errorMessages[fetchResponse.status]
                          } response.`
                        );
                    } else if (fetchResponse.status === 401) {
                      options.showSnackbar &&
                        enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                          variant: 'error',
                        });
                      options.writeConsole &&
                        console.log(
                          `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                            options.errorMessages[fetchResponse.status]
                          } response.`
                        );
                    } else if (fetchResponse.status === 403) {
                      options.showSnackbar &&
                        enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                          variant: 'error',
                        });
                      options.writeConsole &&
                        console.log(
                          `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                            options.errorMessages[fetchResponse.status]
                          } response.`
                        );
                    } else if (fetchResponse.status === 404) {
                      options.showSnackbar &&
                        enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                          variant: 'error',
                        });
                      options.writeConsole &&
                        console.log(
                          `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                            options.errorMessages[fetchResponse.status]
                          } response.`
                        );
                    } else if (fetchResponse.status === 405) {
                      options.showSnackbar &&
                        enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                          variant: 'error',
                        });
                      options.writeConsole &&
                        console.log(
                          `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                            options.errorMessages[fetchResponse.status]
                          } response.`
                        );
                    } else if (fetchResponse.status === 409) {
                      options.showSnackbar &&
                        enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                          variant: 'warning',
                        });
                      options.writeConsole &&
                        console.log(
                          `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                            options.errorMessages[fetchResponse.status]
                          } response.`
                        );
                    } else if (fetchResponse.status === 500) {
                      options.showSnackbar &&
                        enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                          variant: 'error',
                        });
                      options.writeConsole &&
                        console.log(
                          `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                            options.errorMessages[fetchResponse.status]
                          } response.`
                        );
                    } else if (fetchResponse.status === 501) {
                      options.showSnackbar &&
                        enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                          variant: 'error',
                        });
                      options.writeConsole &&
                        console.log(
                          `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                            options.errorMessages[fetchResponse.status]
                          } response.`
                        );
                    } else if (fetchResponse.status === 503) {
                      options.showSnackbar &&
                        enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                          variant: 'error',
                        });
                      options.writeConsole &&
                        console.log(
                          `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                            options.errorMessages[fetchResponse.status]
                          } response.`
                        );
                    } else if (fetchResponse.status === 504) {
                      options.showSnackbar &&
                        enqueueSnackbar(options.errorMessages[fetchResponse.status], {
                          variant: 'error',
                        });
                      options.writeConsole &&
                        console.log(
                          `API ${method} call to ${url} returned a ${fetchResponse.status} ${
                            options.errorMessages[fetchResponse.status]
                          } response.`
                        );
                    } else {
                      enqueueSnackbar('Please try again.', { variant: 'error' });
                    }
                  })
                  .catch((error1) => console.log(error1));
              }
            }
          });
      }
    },
    []
  );

  const setNull = useCallback(() => {
    setIsLoaded(false);
    setStatus(undefined);
    setData(null);
    setErrors(errorsDefault);
  }, []);

  return { data, isLoaded, status, errors, callAPI, setNull };
}

export const callApiWithToken = async (
  accessToken: string,
  apiEndpoint: RequestInfo | URL,
  method: string = 'GET',
  body: any = null
) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  let options: any = {
    method: method,
    headers: headers,
  };

  if (body !== null && body !== undefined) {
    headers.append('Content-Type', 'application/json');
    options = {
      method: method,
      headers: headers,
      body: body,
    };
  }

  return fetch(apiEndpoint, options)
    .then((response) => response.json())
    .then((response) => {
      return new Promise((resolve, reject) => {
        // check for any errors
        if (response.error_codes) {
          /**
           * Conditional access MFA requirement throws an AADSTS50076 error.
           * If the user has not enrolled in MFA, an AADSTS50079 error will be thrown instead.
           * If this occurs, sample middle-tier API will propagate this to client
           * For more, visit: https://docs.microsoft.com/azure/active-directory/develop/v2-conditional-access-dev-guide
           */
          if (response.error_codes.includes(50076) || response.error_codes.includes(50079)) {
            // stringified JSON claims challenge
            reject(response.claims);

            /**
             * If the user has not consented to the required scopes,
             * an AADSTS65001 error will be thrown.
             */
          } else if (response.error_codes.includes(65001)) {
            console.log('REJECT');
            reject();
          }
        } else {
          resolve(response);
        }
      });
    });
};
