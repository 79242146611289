import { useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Collapse } from '@mui/material';
// hooks
import useActiveLink from '../../../../hooks/useActiveLink';
// components
import { NavSectionVertical } from '../../../../components/nav-section';
//
import { NavItemProps } from '../types';
import NavItem from './NavItem';

// Azure AD
import { loginRequest } from '../../../../authConfig';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

// ----------------------------------------------------------------------

type NavListProps = {
  item: NavItemProps;
};

export default function NavList({ item }: NavListProps) {
  const { pathname } = useLocation();

  const { path, children } = item;

  const { isExternalLink } = useActiveLink(path);

  const [open, setOpen] = useState(false);

  const { instance } = useMsal();

  function handleLogout(instance: IPublicClientApplication) {
    instance.logoutPopup().catch((e) => {
      console.error(e);
    });
  }

  async function handleLogin(instance: IPublicClientApplication) {
    try {
      instance.loginPopup(loginRequest).catch((e) => {
        console.error(e);
      });
    } catch (ee) {
      console.error('ee', ee);
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(!open);
    if (item.title === 'Sign-out') {
      handleLogout(instance);
    }

    if (item.title === 'Sign-in') {
      handleLogin(instance);
    }
  };

  return (
    <>
      <NavItem
        item={item}
        open={open}
        // onClick={() => setOpen(!open)}
        active={pathname === path}
        isExternalLink={isExternalLink}
        onClick={handleClick}
      />

      {!!children && (
        <Collapse in={open} unmountOnExit>
          <NavSectionVertical
            data={children}
            sx={{
              '& .MuiList-root:last-of-type .MuiListItemButton-root': {
                height: 160,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                bgcolor: 'background.neutral',
                backgroundRepeat: 'no-repeat',
                backgroundImage: 'url(/assets/illustrations/illustration_dashboard.png)',
                '& > *:not(.MuiTouchRipple-root)': { display: 'none' },
              },
            }}
          />
        </Collapse>
      )}
    </>
  );
}
