// routes
// import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

// const icon = (name: string) => (
//   <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
// );

// const ICONS = {
//   user: icon('ic_user'),
//   ecommerce: icon('ic_ecommerce'),
//   analytics: icon('ic_analytics'),
//   dashboard: icon('ic_dashboard'),
// };

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  code: icon('ic_code'),
  settingConfig: icon('ic_setting_config'),
  statsReport: icon('ic_stats_report'),
  document: icon('ic_document'),
  bracers: icon('ic_bracers'),
  research: icon('ic_research'),
  security: icon('ic_security'),
  chat: icon('ic_chat'),
};

const NavConfigSuperAdmin = [
  // Home
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'Security Frameworks',
  //   items: [{ title: 'Home', path: '/home', icon: ICONS.security }],
  // },

  // ASSESSMENTS
  // ----------------------------------------------------------------------
  {
    subheader: 'Assessments',
    items: [
      { title: 'IT Assessment', path: '/assessment/it', icon: ICONS.dashboard },
      { title: 'Guidance', path: '/guidance', icon: ICONS.security },
    ],
  },

  // ADMIN
  // ----------------------------------------------------------------------
  {
    subheader: 'ADMIN',
    items: [
      {
        title: 'Configuration',
        path: '/admin/configuration',
        icon: ICONS.settingConfig,
        children: [
          {
            title: 'assessment',
            path: '/admin/configuration/assessments',
          },
          {
            title: 'questions',
            path: '/admin/configuration/questions',
          },
          {
            title: 'organisation',
            path: '/admin/configuration/organisation',
          },
          {
            title: 'Compose Guidance',
            path: '/admin/configuration/guidance',
          },
        ],
      },
      {
        title: 'Reports',
        path: '/admin/reports',
        icon: ICONS.statsReport,
        children: [
          {
            title: 'assessment',
            path: '/admin/reports/assessment',
          },
        ],
      },
      {
        title: 'Documentation',
        path: '/admin/documentation',
        icon: ICONS.document,
        children: [
          { title: 'Getting Started', path: '/admin/documentation/getting-started' },
          {
            title: 'Azure AD',
            path: '/admin/documentation/azure-ad',
          },
        ],
      },
    ],
  },

  // API
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'API',
  //   items: [
  //     {
  //       title: 'public',
  //       path: '/api/public',
  //       icon: ICONS.code,
  //       children: [
  //         {
  //           title: 'frameworks',
  //           path: '/api/public/frameworks',
  //           children: [
  //             { title: 'List', path: '/api/public/frameworks/list' },
  //             { title: 'Get', path: '/api/public/frameworks/get' },
  //           ],
  //         },
  //         {
  //           title: 'NIST CSF',
  //           path: '/api/public/nist-csf',
  //           children: [
  //             { title: 'List', path: '/api/public/nist-csf/list' },
  //             { title: 'Get', path: '/api/public/nist-csf/get' },
  //             {
  //               title: 'List Informative Reference',
  //               path: '/api/public/nist-csf/list-infortmative-references',
  //             },
  //             {
  //               title: 'Get Informative Reference',
  //               path: '/api/public/nist-csf/get-infortmative-references',
  //             },
  //           ],
  //         },
  //         {
  //           title: 'NIST 800-53 R3',
  //           path: '/api/public/nist-800-53r3',
  //           children: [
  //             { title: 'List', path: '/api/public/nist-800-53r3/list' },
  //             { title: 'Get', path: '/api/public/nist-800-53r3/get' },
  //           ],
  //         },
  //         {
  //           title: 'ISM',
  //           path: '/api/public/ism',
  //           children: [
  //             { title: 'List', path: '/api/public/ism/list' },
  //             { title: 'Get', path: '/api/public/ism/get' },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       title: 'authenticated',
  //       path: '/api/authenticated',
  //       icon: ICONS.bracers,
  //       children: [
  //         {
  //           title: 'assessments',
  //           path: '/api/authenticated/assessments',
  //           children: [
  //             {
  //               title: 'List Organisation Assessment',
  //               path: '/api/authenticated/assessments/list-organisation-assessment',
  //             },
  //             {
  //               title: 'Get Organisation Assessment',
  //               path: '/api/authenticated/assessments/get-organisation-assessment',
  //             },
  //             {
  //               title: 'Create Organisation Assessment',
  //               path: '/api/authenticated/assessments/create-organisation-assessment',
  //             },
  //             {
  //               title: 'Update Organisation Assessment',
  //               path: '/api/authenticated/assessments/update-organisation-assessment',
  //             },
  //             {
  //               title: 'Delete Organisation Assessmente',
  //               path: '/api/authenticated/assessments/delete-organisation-assessment',
  //             },
  //             {
  //               title: 'List User Assessment',
  //               path: '/api/authenticated/assessments/list-user-assessment',
  //             },
  //             {
  //               title: 'Get User Assessment',
  //               path: '/api/authenticated/assessments/get-user-assessment',
  //             },
  //             {
  //               title: 'Create User Assessment',
  //               path: '/api/authenticated/assessments/create-user-assessment',
  //             },
  //             {
  //               title: 'Delete User Assessment ',
  //               path: '/api/authenticated/assessments/delete-user-assessment',
  //             },
  //             {
  //               title: 'List NSIT CSF Questions',
  //               path: '/api/authenticated/assessments/list-nist-csf-questions',
  //             },
  //             {
  //               title: 'Get NSIT CSF Questions',
  //               path: '/api/authenticated/assessments/get-nist-csf-questions',
  //             },
  //             {
  //               title: 'List NSIT CSF Questions',
  //               path: '/api/authenticated/assessments/create-nist-csf-questions',
  //             },
  //             {
  //               title: 'Get NSIT CSF Questions',
  //               path: '/api/authenticated/assessments/delete-nist-csf-questions',
  //             },
  //           ],
  //         },
  //         {
  //           title: 'roles',
  //           path: '/api/authenticated/roles',
  //           children: [
  //             {
  //               title: 'List',
  //               path: '/api/authenticated/roles/list',
  //             },
  //             {
  //               title: 'Get',
  //               path: '/api/authenticated/roles/get',
  //             },
  //             {
  //               title: 'List Role Assignment',
  //               path: '/api/authenticated/roles/list-role-assignment',
  //             },
  //             {
  //               title: 'Get Role Assignment',
  //               path: '/api/authenticated/roles/get-role-assignment',
  //             },
  //             {
  //               title: 'Create Role Assignment',
  //               path: '/api/authenticated/roles/create-role-assignment',
  //             },
  //             {
  //               title: 'Delete Role Assignment',
  //               path: '/api/authenticated/roles/delete-role-assignment',
  //             },
  //           ],
  //         },
  //         {
  //           title: 'reports',
  //           path: '/api/authenticated/reports',
  //           children: [
  //             {
  //               title: 'List',
  //               path: '/api/authenticated/reports/list',
  //             },
  //             {
  //               title: 'Get',
  //               path: '/api/authenticated/reports/get',
  //             },
  //             {
  //               title: 'List Controls',
  //               path: '/api/authenticated/reports/list-controls',
  //             },
  //             {
  //               title: 'Get Controls',
  //               path: '/api/authenticated/reports/get-controls',
  //             },
  //             {
  //               title: 'Get Category',
  //               path: '/api/authenticated/reports/get-category',
  //             },
  //           ],
  //         },
  //         {
  //           title: 'organisation',
  //           path: '/api/authenticated/organisation',
  //           children: [
  //             {
  //               title: 'Get',
  //               path: '/api/authenticated/organisation/get',
  //             },
  //           ],
  //         },
  //         {
  //           title: 'users',
  //           path: '/api/authenticated/users',
  //           children: [
  //             {
  //               title: 'List',
  //               path: '/api/authenticated/users/list',
  //             },
  //             {
  //               title: 'Get',
  //               path: '/api/authenticated/users/get',
  //             },
  //             {
  //               title: 'Create',
  //               path: '/api/authenticated/users/create',
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    subheader: 'Api',
    items: [{ title: 'Api Reference', path: '/api', icon: ICONS.code }],
  },

  // Research
  // ----------------------------------------------------------------------
  {
    subheader: 'Research',
    items: [{ title: 'Stages', path: '/research', icon: ICONS.research }],
  },

  // About
  // ----------------------------------------------------------------------
  {
    subheader: 'About',
    items: [
      { title: 'About Us', path: '/about-us', icon: ICONS.user },
      { title: 'Contact Us', path: '/contact-us', icon: ICONS.chat },
    ],
  },
];

export default NavConfigSuperAdmin;
