import { useEffect, useState, useContext } from 'react';
// @mui
import { Stack, Box } from '@mui/material';
// config
import { NAV } from '../../../config';
// utils
import { hideScrollbarX } from '../../../utils/cssStyles';
// components
import Logo from '../../../components/logo';
import { NavSectionMini } from '../../../components/nav-section';
//
import navConfigOrgAdmin from './NavConfigOrgAdmin';
import navConfigSuperAdmin from './NavConfigSuperAdmin';
import navConfigUnAuthed from './NavConfigUnAuthed';
import navConfigAuthedITPerm from './NavConfigAuthedITPerm';
// Azure AD
import { useMsal, useAccount, useIsAuthenticated } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { graphConfig } from '../../../authConfig';
// SF
import { SfContext } from '../../../SfContext';

// ----------------------------------------------------------------------

export default function NavMini() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [graphUser, setGraphUser] = useState<string | null>(null);
  const { state, update } = useContext(SfContext);

  let userNav = navConfigUnAuthed;
  if (isAuthenticated) {
    let isITperm = false;
    let isOrgAdmin = false;
    let isSuperAdmin = false;

    if (state.me) {
      state.me.organisationAssessmentPermissions.forEach((permission: any) => {
        if (permission.permission === 'IT') {
          isITperm = true;
        }
      });
    }

    if (isITperm) {
      userNav = navConfigAuthedITPerm;
    }

    if (state.me) {
      if (state.me.rolesAssignments) {
        state.me.rolesAssignments.forEach((roleAssignment: any) => {
          if (roleAssignment.role.role === 'Organisation Admin') {
            isOrgAdmin = true;
          }
          if (roleAssignment.role.role === 'SuperAdmin') {
            isSuperAdmin = true;
          }
        });
      }
    }

    if (isOrgAdmin) {
      userNav = navConfigOrgAdmin;
    } else if (isSuperAdmin) {
      userNav = navConfigSuperAdmin;
    }
  }

  useEffect(() => {
    let responseStatus: string | number | null = null;
    if (account && inProgress === 'none' && graphUser === null) {
      instance
        .acquireTokenSilent({
          scopes: graphConfig.scopes,
          account,
        })
        .then(async (response) => {
          try {
            fetch(`${graphConfig.endpoint}/me/photos/96x96/$value`, {
              method: 'GET', // or 'PUT'
              headers: {
                Authorization: 'Bearer ' + response.accessToken,
                'Content-Type': 'application/json',
              },
            })
              .then((response) => {
                responseStatus = response.status;
                return response.blob();
              })
              .then((imageBlob) => {
                if (responseStatus === '200') {
                  const imageObjectURL = URL.createObjectURL(imageBlob);
                  setGraphUser(imageObjectURL);
                  // document.cookie = `userIcon=${imageObjectURL}`;
                  update({ userIcon: imageObjectURL });
                }
              })
              .catch((error) => console.error('Unable to get items.', error));
          } catch (error) {
            console.log(error);
            throw new InteractionRequiredAuthError(error);
          }
        })
        .catch((error) => {
          console.log(error);
          // in case if silent token acquisition fails, fallback to an interactive method
          if (error instanceof InteractionRequiredAuthError) {
            if (account && inProgress === 'none') {
              instance
                .acquireTokenPopup({
                  scopes: graphConfig.scopes,
                })
                .then(async (response) => {
                  fetch(`${graphConfig.endpoint}/me/photos/96x96/$value`, {
                    method: 'GET', // or 'PUT'
                    headers: {
                      Authorization: 'Bearer ' + response.accessToken,
                      'Content-Type': 'application/json',
                    },
                  })
                    .then((response) => {
                      responseStatus = response.status;
                      return response.blob();
                    })
                    .then((imageBlob) => {
                      if (responseStatus === '200') {
                        const imageObjectURL = URL.createObjectURL(imageBlob);
                        setGraphUser(imageObjectURL);
                        // document.cookie = `userIcon=${imageObjectURL}`;
                        update({ userIcon: imageObjectURL });
                      }
                    })
                    .catch((error) => console.error('Unable to get items.', error));
                })
                .catch((error1) => console.log(error1));
            }
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, inProgress, account]);

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD_MINI },
      }}
    >
      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_DASHBOARD_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX,
        }}
      >
        <Logo sx={{ mx: 'auto', my: 2 }} />

        <NavSectionMini data={userNav} />
      </Stack>
    </Box>
  );
}
