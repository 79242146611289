import { memo, useContext } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, BoxProps, Toolbar } from '@mui/material';
// config
import { HEADER } from '../../../config';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import { NavSectionHorizontal } from '../../../components/nav-section';
//
import navConfigOrgAdmin from './NavConfigOrgAdmin';
import navConfigSuperAdmin from './NavConfigSuperAdmin';
import navConfigUnAuthed from './NavConfigUnAuthed';
import navConfigAuthedITPerm from './NavConfigAuthedITPerm';
// Azure AD
import { useIsAuthenticated } from '@azure/msal-react';
// SF
import { SfContext } from '../../../SfContext';

// ----------------------------------------------------------------------

function NavHorizontal() {
  const isAuthenticated = useIsAuthenticated();
  const theme = useTheme();
  const { state } = useContext(SfContext);

  let userNav = navConfigUnAuthed;
  if (isAuthenticated) {
    let isITperm = false;
    let isOrgAdmin = false;
    let isSuperAdmin = false;

    if (state.me) {
      state.me.organisationAssessmentPermissions.forEach((permission: any) => {
        if (permission.permission === 'IT') {
          isITperm = true;
        }
      });
    }

    if (isITperm) {
      userNav = navConfigAuthedITPerm;
    }

    if (state.me) {
      if (state.me.rolesAssignments) {
        state.me.rolesAssignments.forEach((roleAssignment: any) => {
          if (roleAssignment.role.role === 'Organisation Admin') {
            isOrgAdmin = true;
          }
          if (roleAssignment.role.role === 'SuperAdmin') {
            isSuperAdmin = true;
          }
        });
      }
    }

    if (isOrgAdmin) {
      userNav = navConfigOrgAdmin;
    } else if (isSuperAdmin) {
      userNav = navConfigSuperAdmin;
    }
  }

  return (
    <AppBar
      component="nav"
      color="transparent"
      sx={{
        boxShadow: 0,
        top: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
      }}
    >
      <Toolbar
        sx={{
          ...bgBlur({
            color: theme.palette.background.default,
          }),
        }}
      >
        <NavSectionHorizontal data={userNav} />
      </Toolbar>

      <Shadow />
    </AppBar>
  );
}

export default memo(NavHorizontal);

// ----------------------------------------------------------------------

function Shadow({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        width: 1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
