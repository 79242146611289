import { useEffect, useContext } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Stack, Drawer, Typography, Link } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// config
import { NAV } from '../../../config';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import navConfigOrgAdmin from './NavConfigOrgAdmin';
import navConfigSuperAdmin from './NavConfigSuperAdmin';
import navConfigUnAuthed from './NavConfigUnAuthed';
import navConfigAuthedITPerm from './NavConfigAuthedITPerm';
import NavAccount from './NavAccount';
// Azure AD
import { useIsAuthenticated } from '@azure/msal-react';
// SF
import { SfContext } from '../../../SfContext';

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const isAuthenticated = useIsAuthenticated();
  const { pathname } = useLocation();
  const { state } = useContext(SfContext);
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  let userNav = navConfigUnAuthed;
  if (isAuthenticated) {
    let isITperm = false;
    let isOrgAdmin = false;
    let isSuperAdmin = false;

    if (state.me) {
      state.me.organisationAssessmentPermissions.forEach((permission: any) => {
        if (permission.permission === 'IT') {
          isITperm = true;
        }
      });
    }

    if (isITperm) {
      userNav = navConfigAuthedITPerm;
    }

    if (state.me) {
      if (state.me.rolesAssignments) {
        state.me.rolesAssignments.forEach((roleAssignment: any) => {
          if (roleAssignment.role.role === 'Organisation Admin') {
            isOrgAdmin = true;
          }
          if (roleAssignment.role.role === 'SuperAdmin') {
            isSuperAdmin = true;
          }
        });
      }
    }

    if (isOrgAdmin) {
      userNav = navConfigOrgAdmin;
    }
    if (isSuperAdmin) {
      userNav = navConfigSuperAdmin;
    }
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
        }}
      >
        <Stack direction="row">
          <Logo />
          <Link
            to="/"
            component={RouterLink}
            variant="button"
            color="inherit"
            sx={{ display: 'contents' }}
          >
            <Typography mt={1} mx={1}>
              Security Frameworks
            </Typography>
          </Link>
        </Stack>
        <NavAccount />
      </Stack>

      <NavSectionVertical data={userNav} />

      <Box sx={{ flexGrow: 1 }} />

      {/* <NavDocs /> */}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
              bgcolor: 'transparent',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
